@import "_reset.scss";
@import "../css/animate.css";
@import "hamburgers/hamburgers.scss";

$yellow : #f9cf03;
$yellow-1: #ffd200;
$light-black : #1d1e2a;
$grey: #EEEEEE;
$grey-1: #717271;
$grey-2: #BDBDBD;
$grey-border : #dedede;
$black : #1d1e2a;
$white : #fff;

$break_2 : 999px;
$break_3 : 749px;
$break_4 : 520px;

$grid_0 : 940px;
$grid_3 : 470px;

$regular_font : 300;
$bold_font : 400;


$acagiBold : "Akagi Pro W00 Bold";

// Sezioni
$menu: 'menu-';
$menu-voce-1: 'brand';
$menu-voce-2: 'web';
$menu-voce-3: 'strategy';
$menu-voce-4: 'produzione';
$menu-voce-5: 'offcuts';

// Mixins
@mixin sfondo-menu() {
    &.#{$menu}#{$menu-voce-1} {
        background-image: url('../img/papera-1.svg');
    }

    &.#{$menu}#{$menu-voce-2} {
        background-image: url('../img/cardboard-1.svg');
    }

    &.#{$menu}#{$menu-voce-3} {
        background-image: url('../img/teiera-1.svg');
    }

    &.#{$menu}#{$menu-voce-4} {
        background-image: url('../img/cappellino-1.svg');
    }

    &.#{$menu}#{$menu-voce-5} {
        background-image: url('../img/offcuts-1.svg');
    }
}

a {
    outline: none !important;
}

.bloccato {
    overflow: hidden !important;
}

b,
strong {
    font-weight: $bold_font;
}

.clear {
    clear: both;
}

.disattiva {
    pointer-events: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $regular_font;
}

h6 {
    display: none;
}

h1 {
    font-family: 'Modern No_20 W01 Roman', serif;
    font-weight: lighter;
}

body {
    font-family: "Akagi Pro W00 Thin", sans-serif;
    font-weight: $regular_font;
    overflow-x: hidden;

}

strong {
    font-family: 'Akagi Pro W00 Bold', sans-serif;
    font-weight: bold;
}

// Safari Hack
_::-webkit-full-page-media,
_:future,
:root body {
    -webkit-font-smoothing: antialiased;
}

.corsivo {
    font-style: italic;
}

.disabilita {
    pointer-events: none !important;
}

.main-container-white .followtitleinner {
    background-color: rgba(255, 255, 255, 0.8) !important;
}

.main-container-black .followtitleinner {
    background-color: rgba(29, 30, 42, 0.8) !important;
    color: #fff !important;
}

.mostra {
    opacity: 1 !important;
}

.nascondi {
    display: none !important;
}

.verticale {
    min-height: auto !important;
    height: 100% !important;
}

.webpage {
    svg {
        .cls-1 {
            stroke: #fff;
        }
    }
}

.white {
    background-color: white;
}

.yellow {
    background-color: $yellow;
}

.black {
    background-color: $light-black;
    color: $white;
}

.yellow.scratch {
    background-image: url('../img/scratch-bg.png');
    background-position: top center;
    background-repeat: no-repeat;
}

.container-fluid {
    width: 100%;
    /*min-height: 100vh;*/
    padding-left: 0px;
    padding-right: 0px;
    position: relative;


    .arrow-down {
        bottom: 30px;
        height: 15px;
        position: absolute;
        text-align: center;
        width: 100px;
        z-index: 999;
        left: 0;
        right: 0;
        margin: auto;

        @media screen and (max-width: $break_4) {
            bottom: 20px !important;
        }

        &.ios {
            p {
                line-height: 30px;
            }

            @media screen and (max-width: $break_4) {
                bottom: 95px !important;
            }
        }

        &.iPad {

            bottom: 65px !important;

        }

        img {
            cursor: pointer;

            @media screen and (max-width: $break_4) {
                width: 50px;
            }
        }
    }

    .slider {
        height: 100vh;
        margin-bottom: 0;

        .slick-list,
        .slick-track {
            @media screen and (max-width: $break_4) {
                height: 100%;
            }
        }

        .slick-slide {
            height: 100vh;
            overflow: hidden;
            padding: 0 5vw;
            position: relative;

            @media screen and (max-width: $break_4) {
                height: 100%;
                position: relative;
            }

            .bg-responsive {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0.2;
                background-position: center;
                background-repeat: no-repeat;

                @media screen and (max-width: $break_4) {
                    display: block;
                }
            }

            .slide-main-cont {
                width: $grid_0;
                margin: auto;
                height: 100vh;
                position: relative;

                @media screen and (max-width: $break_2) {
                    width: $grid_3;
                }

                @media screen and (max-width: $break_3) {
                    width: $grid_3;
                }

                @media screen and (max-width: $break_4) {
                    width: 100%;
                    height: 100%;
                }

                h2 {
                    @media screen and (max-width: $break_2) {
                        font-size: 24px !important;
                    }

                    @media (min-width: 481px) and (max-width: 668px) {
                        font-size: 20px !important;
                    }

                    @media (min-width: 481px) and (max-width: 570px) {
                        font-size: 18px !important;
                    }
                }

            }

            .left,
            .right {
                width: $grid_0/2;
                float: left;

                @media screen and (max-width: $break_2) {
                    width: $grid_3;
                }

                @media screen and (max-width: $break_3) {
                    width: $grid_3;
                }

                @media screen and (max-width: $break_4) {
                    width: 100%;
                }
            }

            .left {}

            .right {
                height: 100vh;

                @media screen and (max-width: $break_2) {
                    height: auto !important;
                }

                p {
                    height: 100vh;
                    margin: 0;
                    line-height: 100vh;

                    @media screen and (max-width: $break_2) {
                        height: auto !important;
                        line-height: normal !important;
                    }
                }

                .big-img {
                    width: $grid_0/2;
                    height: auto;
                    vertical-align: middle;
                    display: inline-block;

                    @media screen and (max-width: $break_4) {
                        display: none;
                    }
                }
            }

            .footer-slide {
                position: absolute;
                bottom: 30px;

                p {
                    line-height: 30px;
                }

                @media screen and (max-width: $break_2) {
                    bottom: 10px !important;
                    left: -23%;
                }

                @media screen and (max-width: $break_4) {
                    bottom: 45px !important;
                    left: 0;
                }

                &.ios {
                    @media screen and (max-width: $break_4) {
                        bottom: 110px !important;
                    }
                }

                &.iPad {
                    bottom: 40px !important;
                }

                img {
                    position: relative;
                    float: left;

                    @media screen and (max-width: $break_4) {
                        width: 50px;
                    }
                }

                p {
                    display: inline-block;
                    float: left;
                    font-size: 24px;
                    margin: 0 0 0 20px !important;

                    @media screen and (max-width: $break_4) {
                        font-size: 15px;
                    }

                    strong {
                        font-family: $acagiBold, sans-serif;
                        font-weight: $regular_font;
                    }
                }
            }
        }

        .slick-dots {
            bottom: 50px !important;
            display: block;
            list-style: outside none none;
            padding: 0;
            position: relative;
            text-align: right;
            width: $grid_0;
            margin: auto;
            z-index: 999;

            @media screen and (max-width: $break_2) {
                width: $grid_3;
            }

            @media screen and (max-width: $break_3) {
                width: $grid_3;
            }

            @media screen and (max-width: $break_4) {
                width: 90%;
                bottom: 35px !important;
            }

            &.ios {
                @media screen and (max-width: $break_4) {
                    bottom: 110px !important;
                }
            }

            &.iPad {
                bottom: 60px !important;
            }

            li {
                @media screen and (max-width: $break_4) {
                    width: 10px;
                    height: 10px;
                }
            }

            li button::before {
                content: '';
                width: 15px;
                height: 15px;
                border: 1px solid #000;
                border-radius: 100px;
                opacity: 1;

                @media screen and (max-width: $break_4) {
                    width: 7px;
                    height: 7px;
                }
            }

            li.slick-active button::before {
                background-color: #000;
            }
        }

        h1 {
            color: #1d1f29;
            font-size: 90px;
            line-height: 85px;
            margin: 90px 0 0 0;
            z-index: 100;
            position: relative;

            @media screen and (max-width: $break_4) {
                font-size: 70px !important;
                line-height: 60px;
            }

            @media screen and (max-width: $break_4) {
                margin-top: 85px;
            }
        }

        h2 {
            color: $yellow;
            z-index: 100;
            position: relative;
            font-size: 34px;

            @media screen and (max-width: $break_4) {
                font-size: 20px !important;
                color: #1d1f29 !important;
            }
        }

        img {
            position: relative;
            z-index: 100;
        }

        .yellow {
            h2 {
                color: #fff;
            }
        }

        .slide1 {

            .left {
                float: none;
                margin: auto;

                img {
                    margin: auto;
                    width: 70%;
                    margin-top: 35%;

                    @media screen and (max-width: $break_3) {
                        margin-top: 25%;
                        width: 90%;
                    }

                    @media screen and (max-width: $break_4) {
                        width: 100%;
                    }
                }
            }
        }

        .polygon {
            @media screen and (max-width: $break_4) {
                display: none !important;
            }
        }

        .slide2 {

            .polygon {
                width: 200%;
                left: -50%;
                height: 100%;
                -ms-transform: rotate(7deg);
                /* IE 9 */
                -webkit-transform: rotate(7deg);
                /* Chrome, Safari, Opera */
                transform: rotate(7deg);
                background: rgba(128, 128, 128, 0.3);
                position: absolute;
                top: 50%;
                z-index: 0;
            }

        }

        .slide3 {

            .polygon {
                width: 100%;
                height: 30%;
                background: rgba(128, 128, 128, 0.3);
                position: absolute;
                top: 70%;
                left: 0;
                right: 0;
                z-index: 0;
            }

        }

        .slide4 {

            .polygon {
                border-bottom: 12vw solid transparent;
                border-left: 60vw solid rgba(128, 128, 128, 0.3);
                border-top: 12vw solid transparent;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: 0;
                z-index: 0;

                @media screen and (max-width: $break_2) {
                    top: 42% !important;
                }

            }

            h2 {
                color: #000 !important;
                font-size: 20px;
            }

            img.bg-img {
                @media screen and (max-width: $break_2) {
                    position: absolute;
                    margin-left: 32%;
                    top: 41%;
                }
            }

            .right {
                position: relative;
            }

            .back-image {
                background-image: url('../img/slide4/speakers-corner-2.png');
                background-repeat: no-repeat;
                background-position: center right;
                height: 560px;
                width: 500%;
                position: absolute;
                right: 50px;
                height: 100vh;

                @media screen and (max-width: $break_4) {
                    display: none;
                }

                @media screen and (min-width: $break_4) and (max-width: $break_2) {
                    margin-top: -46% !important;
                }
            }
        }

        .slide5 {
            h1 {
                font-size: 85px;
            }

            .polygon {
                width: 100%;
                height: 20%;
                background: rgba(128, 128, 128, 0.3);
                position: absolute;
                top: 60%;
                left: 0;
                right: 0;
                z-index: 0;
            }

            h1,
            h2 {
                text-align: left;

                // @media screen and (max-width: $break_4) {
                //     text-align:right;
                // }
            }
        }
    }

    .container-slider-home {
        background-color: $grey;

        .slick-slider-home {
            background-color: $white;
            margin: 0 auto;
            max-width: 1440px;
        }
    }

    .cooming-soon {
        text-align: center;
        padding-bottom: 30px;

        img {
            @media screen and (max-width: $break_4) {
                width: 100%;
            }
        }

        h1 {
            font-size: 60px;
            margin: 10px 0;
        }

        p {
            margin: 0;
            font-size: 18px;
        }

        .logotype-content {

            &::before {
                content: '';
                display: block;
                width: 30px;
                height: 2px;
                background-image: url('../img/dotted.png');
                background-repeat: repeat-x;
                margin-top: 50px;
                margin-left: auto;
                margin-right: auto;
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                transform: rotate(90deg);
            }

            .logotype {
                width: 80vw;
                margin: 17px auto 0 auto;
                height: auto;
                display: inline-block;

                &::before {
                    content: '';
                    display: block;
                    width: 74%;
                    height: 2px;
                    background-image: url('../img/dotted.png');
                    background-repeat: repeat-x;
                    margin: 0 13%;
                }

                .logo {
                    position: relative;
                    width: 25%;
                    float: left;
                    display: inline-block;
                    margin: 2px 0 20px 0;

                    @media screen and (max-width: $break_2) {
                        width: 50%;
                    }

                    @media screen and (max-width: $break_4) {
                        width: 100%;
                    }

                    &:before {
                        content: '';
                        display: inline-block;
                        width: 30px;
                        height: 2px;
                        background-image: url('../img/dotted.png');
                        background-repeat: repeat-x;
                        -webkit-transform: rotate(90deg);
                        -moz-transform: rotate(90deg);
                        -ms-transform: rotate(90deg);
                        -o-transform: rotate(90deg);
                        transform: rotate(90deg);

                        @media screen and (max-width: $break_2) {
                            display: none;
                        }
                    }


                    a {
                        display: block;
                        margin: auto;
                        max-width: 100%;

                        background-position: center top;
                        background-repeat: no-repeat;
                    }

                    a.logo1 {
                        margin-top: 40px;
                        height: 70px;
                        background-size: auto 70px;
                        background-image: url('../img/greylab_architettura.png');
                    }

                    a.logo2 {
                        margin-top: 40px;
                        height: 70px;
                        background-size: auto 70px;
                        background-image: url('../img/greylab_industria.png');
                    }

                    a.logo3 {
                        margin-top: 60px;
                        height: 30px;
                        background-size: auto 30px;
                        background-image: url('../img/sanfermosette.png');
                    }

                    a.logo4 {
                        margin-top: 30px;
                        height: 80px;
                        background-size: auto 80px;
                        background-image: url('../img/the_brandist.png');
                    }

                    img {
                        display: block;
                        margin: auto;
                        margin-top: 40px;
                        max-width: 100%;

                        @media screen and (max-width: $break_3) {
                            max-width: 60%;
                        }
                    }

                    &:nth-child(3) {
                        img {
                            margin-top: 60px;
                        }
                    }

                    &:nth-child(4) {
                        img {
                            @media screen and (max-width: $break_3) {
                                max-width: 40%;
                            }
                        }
                    }
                }
            }
        }
    }



    .cont-footer {

        display: block;

        @media (min-width: 768px) {
            padding: 0 32px 0 0;
        }

        @media (min-width: 1200px) {
            padding: 0 24px 0 0;
        }

    }

    .footer {
        color: #fff;
        padding: 6em 40px 5em; // 60px 25px 40px 25px;

        @media screen and (max-width: $break_4) {
            padding-bottom: 20px;
        }

        h1 {
            font-size: 42px; //50px;
            margin: 0;

            @media screen and (max-width: $break_2) {
                font-size: 41px;
            }
        }

        .left,
        .right {
            float: left;
            display: inline-block;

            @media screen and (max-width: $break_2) {}

            @media screen and (max-width: $break_3) {
                float: none;
                width: 100% !important;
            }

            @media screen and (max-width: $break_4) {
                width: 100%;
            }
        }

        .left {
            padding: 0 12px 0 16px;
            width: 65%;

            h1 {
                @media screen and (max-width: $break_4) {
                    font-size: 30px;
                }
            }

            p {
                font-size: 24px;
                margin: 0;

                @media screen and (max-width: $break_4) {
                    font-size: 16px;
                }
            }

            .arrow {
                //width: 95%;
                height: 20px;
                background-image: url('../img/arrow.png');
                background-repeat: no-repeat;
                background-position: center right;

                @media screen and (max-width: $break_4) {
                    display: none;
                }
            }

            .logo {
                margin-top: 30px;

                @media screen and (max-width: $break_4) {
                    display: none;
                }
            }
        }

        .link-project {
            position: relative;

            span {
                z-index: 1000;
                transition: all .3s ease-in-out;
            }

            &::after {
                content: '';
                position: absolute;
                width: 0;
                height: 100%;
                background-color: $yellow-1;
                top: 0;
                left: 0;
                line-height: 10.5rem;
                transition: all .3s ease-in-out;

                @media (min-width: 1200px) {
                    line-height: 9.5rem;
                }
            }

            &:hover {

                &::after {
                    content: 'New Project';
                    color: $black;
                    width: 100%;
                }


                span {
                    opacity: 0;
                }
            }
        }

        .right {
            width: 35%;

            @media screen and (max-width: $break_4) {
                text-align: center;
            }

            .logo-responsive {
                display: none;
                margin-top: 30px;

                @media screen and (max-width: $break_4) {
                    display: inline-block;
                    ;
                }
            }

            .btn {
                padding: .9em 60px; //30px 60px;
                border: 1px solid $yellow;
                font-family: 'Modern No_20 W01 Roman', serif;
                font-size: 30px; //40px;
                display: block;
                color: #fff;
                text-align: center;
                text-decoration: none;

                @media screen and (max-width: $break_2) {
                    font-size: 31px;
                    padding: 30px;
                }

                @media screen and (max-width: $break_3) {
                    margin-top: 30px;
                }

                @media screen and (max-width: $break_4) {
                    width: 80%;
                }

                @media screen and (max-width: 480px) {
                    width: auto;
                }
            }

            .copy {
                font-size: 14px;
                margin-top: 10em; //90px;
                padding: 0 16px 0 0;
                text-align: right;

                @media screen and (max-width: $break_2) {
                    margin-top: 40px;
                }

                @media screen and (max-width: 480px) {
                    text-align: center;
                }
            }
        }

        .top-border {
            border-top: 1px solid $grey-1;
        }

    }

    .footer-bottom {
        #footer-logo {
            display: block;
            //margin-left: 30px;
            margin: .5em 0 2em 24px;
            width: 200px;
            height: 70px;
            background-image: url('../img/footer-logo.svg');
            background-position: top center;
            background-repeat: no-repeat;
            background-size: 200px 70px;

            @media screen and (max-width: 740px) {
                display: inline;
            }
        }

        h3 {
            color: $grey-2;
            font-family: "Akagi Pro W00 Light";
            font-size: 24px;
            margin: 0 0 1em;

            span {
                border-bottom: 1px solid $yellow;
                display: inline-block;
                padding-bottom: 2px;
                margin: 2em 0 0;

                @media (min-width: 768px) {
                    margin: 0;
                }
            }
        }

        p {
            font-family: "Akagi Pro W00 Light";
            font-size: 18px;
            line-height: 3rem; //25px;
        }

        .social {
            a {
                text-decoration: none;

                &:first-child {

                    i {
                        margin: 0 6px 0 0;
                    }
                }

                &:last-child {

                    i {
                        margin: 0 0 0 6px;
                    }
                }

                i {
                    font-size: 3rem;
                    margin: 0 6px;
                }
            }
        }

        ul {
            padding-left: 0px;

            li {
                /*margin-top: 15px;
                margin-bottom: 15px;*/

                font-size: 18px;
                margin: .7em 0 .8em;

                list-style: none;

                a {
                    color: #fff;
                }
            }
        }
    }

    .footer-info {
        background-color: #fff;

        ul {
            list-style-type: none;
            padding: 0 !important;

            @media screen and (max-width: $break_4) {
                text-align: center;
            }

            li {
                display: inline-block;
                padding: 3px 20px;
                color: $light-black;
                font-size: 13px;
                font-weight: lighter;
                border-right: 1px solid $light-black;
                position: relative;

                @media screen and (max-width: $break_2) {
                    font-size: 14px;
                }

                @media screen and (max-width: $break_4) {
                    padding: 0px 13px;
                    border-right: 0px solid $light-black;
                    display: block;
                }

                &:after {
                    @media screen and (max-width: $break_4) {
                        /*content: '-';
                        position: absolute;
                        right: -4px;*/
                    }
                }

                a {
                    color: $light-black;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }
                }

                &:first-child {
                    padding-left: 0;
                }
            }

            li:last-child {
                border: 0;
            }
        }
    }

    #sfondo-servizi {
        background-attachment: scroll;
        background-color: $yellow-1;
        background-image: url('../img/sfondo-servizi.svg');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100%;
        min-height: auto;
        height: 0;
        position: absolute;
        top: 0;
        transition: all 2s ease-out;
        width: 100%;
        z-index: -1;
    }
}

.effect {
    position: relative;
    padding: 30px;

    /*&.reduced-left{
        @media screen and (max-width: 480px) {
            padding-left:45px;
        }
        @media screen and (max-width: 769px) {
            padding-left:60px;
        }
    }
    &.reduced-right{
        @media screen and (max-width: 480px) {
            padding-right:45px;
        }
        @media screen and (max-width: 769px) {
            padding-right:60px;
        }
    }*/

    .list-container {
        opacity: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        transition: all 0.50s;

        ul {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            list-style: none;
            margin: 0px;
            color: $light-black;
        }

        ul li {
            font-family: "Akagi Pro W00 Bold";
            font-size: 21;
            position: relative;
            padding-left: 20px;
        }

        ul li:before {
            content: '';
            background: #fff;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            width: 5px;
            height: 5px;
            position: absolute;
            top: 40%;
            transform: translateY(-40%);
            left: 5px;
        }
    }

    &:hover {
        .list-container {
            opacity: 1;
        }
    }
}

.effect.large {
    .svg-container {
        svg {
            height: auto; //110px; //150px;
            //max-width: 77px; //110px; //150px;

            @media (min-width: 480px) {
                max-width: 175px; //125px;
            }

            @media screen and (max-width: 481px) {
                height: auto;
                min-height: 95px;
                width: auto;
            }

            @media screen and (min-width: 768px) and (max-width: 991px) {
                height: auto;
                min-height: 155px;
                width: auto;
            }
        }
    }
}

.effect.small {
    .svg-container {
        svg {
            height: 90px;
            max-width: 90px;

            @media screen and (max-width: 480px) {
                height: 70px;
                max-width: 70px;
            }
        }
    }
}

.effect-over-grey {
    background: rgba(29, 30, 42, 0);
    padding: 2em 16px;
    margin: 0 0 3em;
    transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -webkit-transition: all .25s ease-in-out;

    @media (min-width: 480px) {
        margin: 0 0 6em;
    }

    @media (min-width: 768px) {
        padding: 2em 16px;
        margin: 0;
    }

    a {
        text-decoration: none;
    }

    [data-target="#service-logo-1"],
    [data-target="#service-logo-2"],
    [data-target="#service-logo-3"],
    [data-target="#service-logo-4"],
        {
        h2 {
            top: 6.1em;

            @media (min-width: 375px) {
                top: 7.2em;
            }

            @media (min-width: 480px) {
                top: 8.3em;
            }

            @media (min-width: 768px) {
                top: 6.1em;
            }
        }
    }

    .svg-container {
        text-align: center;

        #papera-rollover,
        #cardboard-rollover,
        #teiera-rollover,
        #cappellino-rollover {
            display: none;
            margin: 0 auto;
        }

        svg {
            path {
                stroke: #000;

                -webkit-transition: all 0.50s ease-in-out;
                /* Safari 3.1 to 6.0 */
                transition: all 0.50s;
            }
        }
    }

    h2 {
        text-align: center;
        color: $light-black;
        font-family: 'Modern No_20 W01 Roman', serif;
        position: absolute;
        left: 0;
        right: 0;
        -webkit-transition: all 0.50s ease-in-out;
        /* Safari 3.1 to 6.0 */
        transition: all 0.50s;

        @media screen and (max-width: 481px) {
            font-size: 25px;
        }

    }

    p {
        opacity: 0;
        color: #fff;
        font-family: 'Akagi Pro W00 Thin', sans-serif;
        font-size: 2rem;
        font-weight: lighter;
        padding-top: .5em;
        text-align: justify;
        -webkit-transition: all 0.50s ease-in-out;
        /* Safari 3.1 to 6.0 */
        transition: all 0.50s;

        @media screen and (max-width: 481px) {
            display: none;
        }

        @media (min-width: 768px) {
            font-size: 1.3rem;
        }

        @media (min-width: 992px) {
            font-size: 2rem;
        }

        /*@media screen and (max-width: 769px) {
            opacity: 1;
            color:$black;
        }*/
    }

    &:hover {
        background: rgba(29, 30, 42, 1);

        .svg-container {

            #papera,
            #cardboard,
            #teiera,
            #cappellino {
                display: none;
            }

            #papera-rollover,
            #cardboard-rollover,
            #teiera-rollover,
            #cappellino-rollover {
                display: block;
            }

            svg {
                max-width: 175px;

                path {
                    stroke: #fff;
                }

            }
        }

        h2 {
            color: $yellow;
            position: relative;
            top: auto;
        }

        p {
            opacity: 1;
        }
    }
}

.effect-over-yellow {

    /*background: rgba(29, 30, 42, 0);*/
    padding: 0 !important;
    transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -webkit-transition: all .25s ease-in-out;

    a {
        text-decoration: none;
    }

    .svg-container {
        text-align: center;

        svg {
            opacity: 1;

            -webkit-transition: all 0.50s ease-in-out;
            /* Safari 3.1 to 6.0 */
            transition: all 0.50s;

            path {
                stroke: #000;

                -webkit-transition: all 0.50s ease-in-out;
                /* Safari 3.1 to 6.0 */
                transition: all 0.50s;
            }
        }
    }


    &:hover {
        background: rgba(249, 207, 3, 1);

        .svg-container {
            svg {
                opacity: 0.01;
            }
        }

    }
}

.bg-covered {
    background-size: cover;
    background-position: center;
}

.followtitleinner {
    z-index: 1000;
}

#generatore-container {
    background-color: $grey;

    #generatore {
        margin: 0 auto;
        max-width: 1440px;
        padding: 0 16px;

        @media (min-width: 768px) {
            padding: 0 30px 30px 30px;
        }

        .container {
            height: auto;
            padding: 100px 0 50px;

            @media (min-width: 992px) {
                height: 630px;
                padding: 100px 16px;
            }

            @media (min-width: 1200px) {
                padding: 100px 0;
            }

            .moods {
                float: none;
                margin: 0 auto;
                width: 100%;

                @media (min-width: 768px) {
                    float: left;
                    margin: 0 80px 0 25px;
                    width: 45%;
                }

                .mood {
                    margin: 0 auto;

                    @media (min-width: 768px) {
                        margin: 0 0 0 -20px;
                    }
                }

                &.random {
                    text-align: center;

                    @media (min-width: 768px) {
                        text-align: left;
                    }
                }
            }

            .stile {
                float: none;
                width: 100%;

                @media (min-width: 768px) {
                    float: left;
                    width: 30%;
                }

                &.genera {
                    text-align: center;

                    @media (min-width: 768px) {
                        text-align: left;
                    }
                }

                label {
                    margin: 3em auto 5px;

                    @media (min-width: 768px) {
                        margin: 0 auto 5px;
                    }
                }

                .style {
                    height: 9em;
                    margin: 0 auto;
                    width: 50%;

                    @media (min-width: 768px) {
                        height: auto;
                        margin: 0 0 0 -10px;
                        width: auto;
                    }
                }
            }
        }

        .container-fluid {
            transform-style: preserve-3d;

            &.vh100 {
                height: auto;

                @media (min-width: 992px) {
                    height: 100vh;
                }
            }

            .container {
                width: 100%;

                @media (min-width: 992px) {
                    position: relative;
                    top: 50%;
                    transform: perspective(1px) translateY(-50%);
                    width: 900px;
                }

                .col1,
                .col2 {
                    display: block;
                    float: none;
                    margin: 0 auto;
                    width: 100%;

                    @media (min-width: 992px) {
                        display: inline-block;
                    }
                }

                .col1 {

                    @media (min-width: 768px) {
                        width: 540px;
                    }

                    @media (min-width: 992px) {
                        float: left;
                        padding: 0 0 0 16px;
                    }

                    @media (min-width: 1200px) {
                        padding: 0;
                    }

                    .row {

                        &,
                        &:first-child,
                        &:nth-child(2) {
                            margin: 5px auto 15px;

                            @media (min-width: 992px) {
                                margin: 5px -25px 15px;
                            }
                        }
                    }
                }

                .col2 {
                    height: 273px;

                    @media (min-width: 375px) {
                        height: 310px;
                        width: 310px;
                    }

                    @media (min-width: 992px) {
                        float: right;
                    }

                    .share {

                        i,
                        i.genericon-download {
                            font-size: 17px;
                        }

                        i {
                            @media (min-width: 375px) {
                                font-size: 20px;
                            }
                        }

                        i.genericon-download {
                            @media (min-width: 375px) {
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
        }

        .generated-logo {

            &,
            &:before,
            img {
                height: 273px;
                width: 100%;

                @media (min-width: 375px) {
                    height: 300px;
                    width: 300px;
                }
            }
        }

        input {
            margin: 0 auto;
        }

        label[for="selettore-occupazione"] {
            margin: 0;
        }

        .logo-1 {
            display: block;
            margin: 0 auto 1em;
        }

        .row {

            &,
            &:last-child {
                margin: 5px auto 15px;

                @media (min-width: 992px) {
                    margin: 5px -25px 15px;
                }
            }
        }

        select {
            border-radius: 0;
            margin: 0 auto;
            -webkit-box-shadow: inset 0 0 7px #aeb1b1;
            -moz-box-shadow: inset 0 0 7px #aeb1b1;
            box-shadow: inset 0 0 3px #aeb1b1;
            background-color: #edf3f3;
            border: 0;
            position: relative;
            padding: 0px 15px;
            top: 0px;
            width: 250px;
            height: 30px;
            font-size: 18px;
            color: rgba(0, 0, 0, .6);

            option {

                &,
                &:checked {
                    color: rgba(0, 0, 0, .6);
                }
            }
        }

        #slider-contain {
            background-image: url('../img/0006_Binario-300-+-label.png') !important;
            /*background-position: -10px;*/
            background-repeat: no-repeat !important;
            /*background-size: 104%;*/
            margin: 10px auto 0;

            @media (min-width: 768px) {
                background-image: url('/generatore/assets/generatore/img/0006_Binario-+-label.png') !important;
                background-position: center;
                background-size: contain;
                margin: 10px 0 0 -5px;
            }

            &,
            #slider {
                width: 100%;
            }

            & {
                @media (min-width: 768px) {
                    width: 553px;
                }
            }

            #slider {
                @media (min-width: 768px) {
                    width: 500px;
                }

                /*.ui-slider-handle.ui-corner-all.ui-state-default {
                    margin: 0 0 0 10px;
                }*/
            }
        }
    }
}


.home-clients-cnt {
    margin-top: 20px;

    @media screen and (min-width: 768px) and (max-width: 991px) {
        margin-top: 40px;
    }

    @media screen and (min-width: 992px) {
        margin-top: 40px;
    }
}

#chisiamo-homepage {
    min-height: 300px;
    overflow: hidden;
    font-family: "Akagi Pro W00 Light";
    font-size: 16px;
    text-align: center;

    @media (min-width: 1200px) {
        text-align: left;
    }

    &>div {

        &:nth-child(2) {

            @media (min-width: 1200px) {
                height: 100%;
                position: relative;
                transform-style: preserve-3d;
            }

            &>.row {
                @media (min-width: 1200px) {
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    height: auto;
                }

                &>div {
                    //padding: 0 16px;

                    @media (min-width: 1200px) {
                        padding: 0 0 0 48px;
                    }
                }
            }
        }
    }

    #bohost-who,
    #container-bohost p,
    #logo-who,
    #logo-who+p {
        opacity: 0;
    }


    #bohost-who {
        animation-delay: 1.5s;
    }

    #container-bohost {

        margin: 4em 0 0;

        @media (min-width: 1200px) {
            margin: 1em 0 0;
        }

        p {
            animation-delay: 1.7s;
            margin-bottom: 0;
            margin-top: 2em;

            @media (min-width: 992px) {
                margin-top: 4.5em;
                max-width: 84%;
            }

            @media (min-width: 1200px) {
                margin-top: 2em;
                max-width: 84%;
            }
        }
    }

    .first-row {
        padding: 0 0 4em;

        @media (min-width: 992px) {}

        @media (min-width: 1200px) {
            padding: 0;
        }
    }

    p {
        font-size: 16px;
        margin-top: 2em;

        @media (min-width: 768px) {
            margin-left: auto;
            margin-right: auto;
            max-width: 82%;
        }

        @media (min-width: 992px) {
            max-width: none;
        }

        @media (min-width: 1200px) {
            margin-bottom: 6em;
            margin-left: 0;
            max-width: 84%;
        }
    }

    #chisiamo-homepage-cover {}

    .last-row {
        @media screen and (min-width: 768px) and (max-width: 991px) {
            padding-top: 25px;
            padding-right: 25px;
        }

        .col-xs-6 {
            @media screen and (max-width: 480px) {
                padding: 0;
            }
        }

        .col-sm-6 {
            @media screen and (max-width: 991px) {
                padding: 0;
            }
        }
    }

    #logo-who {

        margin: 3em 0 0;

        @media (min-width: 1200px) {
            margin: 0;
        }

        &+p {
            animation-delay: .5s;
            margin-top: 2em;
            padding: 0 16px;

            @media (min-width: 992px) {
                margin-top: 3.2em;
            }

            @media (min-width: 1200px) {
                margin-top: 2em;
                padding: 0;
            }
        }
    }
}

#clients-container {

    padding: 1.5em 0 3em;

    @media (min-width: 768px) {
        padding: 1.5em 0 3.5em;
    }

    .effect-over-yellow {

        margin: 0 8%;

        @media (min-width: 768px) {
            margin: 0 4%;
        }

        &:hover {
            background-color: transparent;
        }
    }
}

.no-gutter {
    margin-right: 0 !important;
    margin-left: 0 !important;

}

.no-padding {
    padding-left: 0;
    padding-right: 0;
}

.top-border {
    border-top: 1px solid #fff;
}

#crafting-container-tp {
    position: absolute;
    background: transparent;
    z-index: 100;
    width: 100%;

    .title {
        position: absolute;

        h2 {
            font-family: "Akagi Pro W00 Light";
            font-size: 88px;
            margin: 0;

            @media screen and (max-width: 480px) {
                font-size: 45px;
            }
        }

        h3 {
            font-family: "Akagi Pro W00 Bold";
            font-size: 88px;
            margin: 0;

            @media screen and (max-width: 480px) {
                font-size: 45px;
            }
        }
    }

    .paragraph {
        position: absolute;
        font-family: "Akagi Pro W00 Light";
        font-size: 24px;
        margin: 0;

        @media screen and (max-width: 480px) {
            font-size: 18px;
        }
    }


    @media (min-width: 481px) and (max-width: 740px) {
        .title {

            h2 {
                font-size: 55px;
            }

            h3 {
                font-size: 55px;
            }
        }

        .paragraph {
            font-size: 18px;
        }
    }

}

.crafting-container {
    background-color: $grey;
    position: relative;

    .crafting {
        background-color: $white;
        height: 100vh;
        margin: 0 auto;
        max-width: 1440px;
        padding: 1em 0;

        @media (min-width: 768px) {
            padding: 8em 0;
        }

        &.crafting-1 {

            height: 76em !important;

            @media (min-width: 480px) {
                height: 74em !important;
            }

            @media (min-width: 768px) {
                height: 100vh !important;
            }

            &>div:first-child {
                z-index: 1000;

                h1,
                h2 {
                    opacity: 0;
                    padding: 0 16px;
                    text-align: center;

                    @media (min-width: 768px) {
                        padding: 0;
                        text-align: left;
                    }
                }

                h1 {
                    animation-delay: .2s;
                    color: $yellow-1;
                    font-family: 'Akagi Pro W00 Bold', sans-serif;
                    font-size: 9rem;
                    font-weight: bold;
                    margin: .7em auto .3em;


                    @media (min-width: 480px) {
                        font-size: 11rem;
                    }
                }

                h2 {
                    animation-delay: .5s;
                    color: $black;
                    font-family: 'Modern No_20 W01 Roman', serif;
                    font-size: 3rem;
                    line-height: 4.5rem;
                    margin: 0;

                    @media (min-width: 992px) {
                        font-size: 4rem;
                        line-height: 5.5rem;
                    }
                }
            }

        }

        &.crafting-2 {

            height: 65em !important;
            padding: 1em 16px;

            @media (min-width: 480px) {
                height: 74em !important;
            }

            @media (min-width: 768px) {
                height: 100vh !important;
            }

            @media (min-width: 768px) {
                padding: 8em 0;
            }

            @media (min-width: 992px) {
                padding: 3em 0;
            }

            @media (min-width: 1200px) {
                padding: 8em 0;
            }

            &>div:first-child {

                h2,
                h3 {
                    color: $black;
                }

                h2,
                h3,
                p {
                    opacity: 0;
                }

                h2 {
                    font-family: 'Akagi Pro W00 Thin', sans-serif;
                    font-size: 5.2rem;
                    font-weight: lighter;
                    margin: .7em 0 0;

                    @media (min-width: 480px) {
                        font-size: 8.2rem;
                    }

                    @media (min-width: 768px) {
                        font-size: 5.2rem;
                    }

                    @media (min-width: 992px) {
                        font-size: 7.2rem;
                    }

                    @media (min-width: 1200px) {
                        font-size: 8.2rem;
                    }
                }

                h3 {
                    animation-delay: .2s;
                    font-family: 'Akagi Pro W00 Bold', sans-serif;
                    font-size: 5rem;
                    font-weight: bold;
                    margin: 0 0 .5em;

                    @media (min-width: 480px) {
                        font-size: 8rem;
                    }

                    @media (min-width: 768px) {
                        font-size: 6rem;
                    }

                    @media (min-width: 992px) {
                        font-size: 8rem;
                    }

                    @media (min-width: 1200px) {
                        font-size: 9rem;
                    }
                }

                p {
                    animation-delay: .7s;
                    font-family: 'Akagi Pro W00 Thin', sans-serif;
                    font-size: 2.2rem;
                    font-weight: lighter;

                    a {
                        border: 1px solid $yellow-1;
                        display: block;
                        font-family: 'Modern No_20 W01 Roman', serif;
                        margin: 2em auto 0;
                        padding: 1em 16px;
                        text-align: center;
                        text-decoration: none;
                        transition: all .3s ease-in-out;
                        width: 62%;

                        @media (min-width: 1200px) {
                            width: 38%;
                        }

                        &:hover {
                            background-color: $light-black;
                            color: $white;
                        }
                    }
                }
            }

        }
    }

    .crafting-1-sfondo {
        /*background-size: 67%;
        background-position: 0 0;*/
        animation-delay: .5s;
        background-repeat: no-repeat;
        min-height: 44em;
        opacity: 0;
        position: absolute;
        margin: 3em 0 0;
        //max-width: calc(100% - 652px);
        width: 100%;

        @media (min-width: 768px) {
            margin: 0 0 0 42%;
        }

        @media (min-width: 992px) {
            margin: 0 0 0 32%;
        }

        @media (min-width: 1200px) {
            margin: 0 0 0 22%;
        }
    }

    .crafting-2-sfondo {
        animation-delay: .5s;
        background-attachment: scroll;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 63%;
        min-height: 16em;
        opacity: 0;

        @media (min-width: 375px) {
            min-height: 21em;
        }

        @media (min-width: 480px) {
            min-height: 25em;
        }

        @media (min-width: 568px) {
            min-height: 30em;
        }

        @media (min-width: 768px) {
            min-height: 34em;
        }
    }
}

.emotional {

    .title {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 25px;
        background-color: rgba(250, 208, 0, 0.8);

        h1 {
            font-family: "Akagi Pro W00 Bold";
            font-size: 88px;
            margin: 0;
        }

        p {
            font-family: "AkagiProW00-LightItalic";
            font-size: 24px;
            margin: 0;
            position: relative;
            top: -8px;
        }
    }
}

.content-spot {
    h1 {
        font-family: "Akagi Pro W00 Bold";
        font-size: 88px;
        margin: 25px 0 10px 0;
    }

    h2 {
        font-family: "Modern No_20 W01 Roman";
        font-size: 45px;
        margin: 15px 0 5px 0;
    }

    h3 {
        font-family: "AkagiProW00-BoldItalic";
        font-size: 24px;
        margin: 5px 0;
    }

    p {
        font-family: "Akagi Pro W00 Light";
        font-size: 24px;
        margin: 5px 0;
    }

    .svg-container {
        padding: 10px 50px;
        text-align: center;

        svg {
            height: auto;

            @media (max-width: 767px) {
                width: 62%;
            }

            @media (min-width: 768px) {
                width: 100%;
            }
        }
    }

}

.bg-footer {
    background-color: $light-black;
}


.vh100 {
    height: 100vh;

    &.no-sx {

        /*@media (max-width: 480px) {*/
        @media (max-width: 740px) {
            height: auto;
        }
    }

    @media (max-height: 550px) {
        height: auto;
    }
}

.relative {
    position: relative;
}

.footer {
    @media screen and (max-width: 740px) {
        text-align: center;
    }
}

.sectionspacer {
    margin-top: 40px;

    @media screen and (min-width: 768px) and (max-width: 991px) {
        margin-top: 80px;
    }

    @media screen and (min-width: 992px) {
        margin-top: 150px;
    }
}

.card-content {
    overflow: hidden;
    height: 400px;
    position: relative;
    padding: 0;

    &.black {
        color: $white;
    }

    .crb-vertical-align {
        position: absolute;
        top: 50%;
        transform: translateY(-45%);
        padding: 0 40px;

        h3 {
            font-size: 36px;
            line-height: 38px;
        }

        p {
            font-size: 24px;
            line-height: 26px;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        height: 420px;
    }

    @media screen and (min-width: 992px) {
        height: 480px;
    }
}



#header {
    position: fixed;
    z-index: 2000; //200;
    top: 0;
    width: 100%;
    height: 40px;
    //background:$white;
    //border-bottom:2px solid $grey-border;

    -webkit-transition: all 0.50s ease-in-out;
    /* Safari 3.1 to 6.0 */
    transition: all 0.50s;

    &.headerInner {
        &.off {
            top: -40px;
            opacity: 0;
        }
    }

    div {
        left: 0;
        margin: 0 auto;
        max-width: 1440px;
        position: relative;
        right: 0;
    }

    #logo-container {
        #logo-h {
            height: 80px;
            width: 80px;
            background-image: url('../svg/own-logo/gl-stroke-grigio.svg');
            background-position: 0px -2px;
            background-repeat: no-repeat;
            background-size: 75px 75px;
            display: block;

            -webkit-transition: all 0.50s ease-in-out;
            /* Safari 3.1 to 6.0 */
            transition: all 0.50s;

        }
    }

    #main-navigation {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            text-align: right;
            height: 40px;

            li {
                margin-left: 15px;
                margin-right: 15px;
                margin-top: 10px;
                display: inline-block;
                position: relative;
                font-size: 14px;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 2px;

                    background: $yellow;
                    opacity: 0;

                    -webkit-transition: all 0.50s ease-in-out;
                    /* Safari 3.1 to 6.0 */
                    transition: all 0.50s;
                }

                a {
                    &:hover {
                        text-decoration: none;
                    }
                }

                &:hover {
                    &:after {
                        opacity: 1;
                        width: 100%;
                    }
                }

                &.active {
                    a {
                        font-family: $acagiBold, serif;
                    }
                }

            }
        }
    }

    &.headerHome {


        opacity: 0;
        top: -40px;
        background: transparent;
        border-bottom: 0px solid $grey-border;

        &.show {
            top: 0px;
            opacity: 1;
        }

        #logo-h {
            background-image: url('../svg/own-logo/gl-stroke-bianco.svg') !important;
            background-position: 0px -9px !important;

            &:hover,
            &:active {
                background-image: url('../svg/own-logo/gl-stroke-blu.svg') !important;
            }

        }

        #main-navigation {
            text-align: right;

            ul {
                background: $white;
                display: inline-block;
                border-bottom: 2px solid $grey-border;
            }
        }
    }
}

.crb-vertical-align {
    position: absolute;
    top: 50%;
    transform: translateY(-45%);
    padding: 0 25px;
    width: 100%;
    left: 0;
}

.flip-container {

    &:hover {
        .svg-container {
            background-color: #f9cf03;

            img {
                opacity: .1;
            }
        }
    }

    .list-container {

        ul {
            margin: 0 auto;
            padding: 0 8px;

            @media (min-width: 480px) {
                padding: 0 24px;
            }

            li {
                font-family: 'Akagi Pro W00 Book', sans-serif;
                font-size: .9rem;

                @media (min-width: 480px) {
                    font-size: 1.2rem;
                }

                @media (min-width: 992px) {
                    font-size: 1.5rem;
                }

                @media (min-width: 1200px) {
                    font-size: 1.7rem;
                }
            }
        }
    }
}

#offcuts {
    background-color: $grey;
    padding: 2em 32px;

    @media (min-width: 992px) {
        padding: 5em 32px;
    }

    @media (min-width: 1920px) {
        padding: 5em 0;
    }

    .headline,
    .mosaico {
        max-width: 1120px;
    }

    .headline {
        color: $light-black;
        margin: 0 auto;

        article {

            aside {
                margin: 5em 0 0;
                text-align: center;

                @media (min-width: 768px) {
                    margin: 0;
                }

                img {
                    height: auto;
                    max-width: 237px;
                    width: 100%;

                    @media (min-width: 768px) {
                        max-width: none;
                    }
                }
            }

            h2,
            h3,
            p {
                font-size: 24px;
            }

            h1 {
                font-family: "Akagi Pro W00 Bold", sans-serif;
                font-size: 88px;
                margin: .3em 0 0;
            }

            h2 {
                font-family: "AkagiProW00-LightItalic", sans-serif;
                margin: 0;
            }

            h3 {
                font-family: "AkagiProW00-BoldItalic", sans-serif;
                margin: 2em 0 0;
            }

            p {
                font-family: "Akagi Pro W00 Light", sans-serif;
                margin: 0;
            }
        }
    }

    .mosaico {
        background-color: $white;
        padding: 1em 0 0 16px;
        margin: 5em auto 0;

        .caricamento {
            background-attachment: scroll;
            background-image: url('../../img/spinner.gif');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 100%;
            display: none;
            height: 42px;
            margin: 2em auto;
            width: 42px;
        }

        .grid {
            margin: 0 auto;
        }

        .grid::after {
            content: '';
            display: block;
            clear: both;
        }

        .grid-item,
        .grid-sizer {
            width: calc(100% - 16px);

            @media (min-width: 480px) {
                width: 47%;
            }

            @media (min-width: 768px) {
                width: 31.5%;
            }

            @media (min-width: 992px) {
                width: 24%;
            }
        }

        .grid-item {
            float: left;
            margin: 0 0 16px;
            text-align: center;

            &.due {

                @media (min-width: 768px) {
                    width: calc(50% - 16px);
                }
            }

            &.tre {

                @media (min-width: 768px) {
                    width: calc(75% - 16px);
                }
            }

            &.quattro {

                @media (min-width: 768px) {
                    width: calc(100% - 16px);
                }
            }

            img,
            video {
                height: auto;
                width: 100%;
            }
        }

        .gutter-sizer {
            width: 0;

            @media (min-width: 480px) {
                width: 3%;
            }

            @media (min-width: 768px) {
                width: 1.5%;
            }

            @media (min-width: 992px) {
                width: 1%;
            }
        }
    }
}

#references {
    h2 {
        font-size: 3rem;
        text-align: center;

        @media (min-width: 480px) {
            font-size: 4rem;
        }

        @media (min-width: 768px) {
            font-size: 6rem;
            text-align: left;
            text-indent: 32px;
        }

        @media (min-width: 992px) {
            text-indent: 72px;
        }
    }
}

.slik-xs-slider {
    .slick-dots {
        bottom: 0 !important;
    }
}

#social-cnt {
    li {
        margin: 1.1em 0;

        a {
            text-decoration: none;
        }
    }
}


.title {
    &.move-to-anchor {
        position: fixed;
        bottom: 0px;
        height: 145px;
        //background-color:  rgba(250,208,0,0.8);

        h1 {
            font-family: "Akagi Pro W00 Bold";
            font-size: 88px;
            margin: 0;

            &.produzione {
                font-size: 52px;

                @media (min-width: 375px) {
                    font-size: 62px;
                }

                @media (min-width: 480px) {
                    font-size: 84px;
                }

                @media (min-width: 768px) {
                    font-size: 88px;
                }
            }

            &.strategy {
                font-size: 70px;

                @media (min-width: 375px) {
                    font-size: 84px;
                }

                @media (min-width: 480px) {
                    font-size: 88px;
                }
            }
        }

        &.placed {
            position: absolute;
            bottom: -150px;
        }
    }
}



#mobile-navigation {
    display: none;
}



/* This only works with JavaScript,
if it's not present, don't show loader */
.se-pre-con {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('../svg/own-logo/gl-in-on.svg') center no-repeat $yellow;
    background-size: 300px auto;
    opacity: 1;

    -webkit-transition: all 0.65s ease-in-out;
    /* Safari 3.1 to 6.0 */
    transition: all 0.65s;

    &.loaded {
        height: 0px;
        opacity: 0;
    }


    .spinner-loader {
        bottom: 100px;
        opacity: 1;
        position: absolute;
        width: 100%;

        -webkit-transition: all 0.95s ease-in-out;
        /* Safari 3.1 to 6.0 */
        transition: all 0.95s;

        .spinner {
            width: 100px;
            height: 100px;
            margin: 0px auto;
            background-color: $white;

            border-radius: 100%;
            -webkit-animation: sk-scaleout 0.85s infinite ease-in-out;
            animation: sk-scaleout 0.85s infinite ease-in-out;
        }
    }

    &.loaded {
        opacity: 0;
        height: 0%;

        .spinner-loader {
            bottom: 300px;
            opacity: 0;
        }
    }
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0)
    }

    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
}



#home-services-title-content {
    /*
    position: absolute;
    top: 5%;
    padding: 0 25px;
    width: 100%;
    left: 0;
    */
    margin-top: 3em; //100px;
    position: relative;

    @media (min-width: 480px) {
        margin-top: 4em;
    }

    @media (min-width: 768px) {
        margin-top: 12em;
    }

    #main-service-container {
        margin: 0 auto;
        max-width: 197px;
        width: 100%; //300px;
        position: relative;

        @media (min-width: 480px) {
            max-width: 300px;
        }

        @media (min-width: 992px) {
            max-width: 409px;
        }

        #main-home-logo {
            animation-delay: .2s;
            display: block;
            margin: 0 auto;
            opacity: 0;
        }

        .service-label {
            position: absolute;
            display: none;

            @media (max-width: 767px) {
                display: none !important;
            }
        }

        #service-logo-1 {
            width: 145px;
            right: -126px;
            top: -48px;
        }

        #service-logo-2 {
            width: 95px;
            right: -75px;
            top: -20px;
        }

        #service-logo-3 {
            width: 160px;
            right: -148px;
            top: -52px;
        }

        #service-logo-4 {
            width: 225px;
            right: -206px;
            top: -76px;
        }

    }

}

#home-services-btn-content {

    padding: 0 0 2.5em;

    @media (min-width: 480px) {
        padding: 4em 0 0;
    }

    @media (min-width: 768px) {
        padding: 5em 0;
    }

    &>div:nth-child(3) {
        clear: both;

        @media (min-width: 992px) {
            clear: none;
        }
    }

    &>div:nth-child(odd) {

        h2 {
            animation-delay: 1.5s;
        }

        .svg-container {
            animation-delay: 1s;
        }
    }

    &>div:nth-child(even) {

        h2 {
            animation-delay: 1.7s;
        }

        .svg-container {
            animation-delay: 1.2s;
        }
    }

    h2,
    .svg-container {
        opacity: 0;
    }
}

#home-services-btn-content-x {
    position: absolute;
    top: 50%;
    transform: translateY(-45%);
    padding: 0 25px;
    width: 100%;
    left: 0;
}

#what,
#references {

    .vh100 {
        height: auto;

        @media (min-width: 992px) and (min-height: 768px) {
            height: 100vh;
        }
    }
}

#who {

    &>.container-fluid {

        &>.row {
            width: 100%;

            @media (min-height: 768px) {
                width: auto;
            }
        }
    }

    .vh100 {
        height: auto;

        @media (min-width: 1200px) {
            height: 100vh;
        }
    }
}

// Menu
#menu {
    background-color: $white;
    bottom: auto;
    top: -1000%;
    height: 100%;
    left: 0;
    min-height: 100%;
    position: fixed;
    right: 0;
    transition: all 1s ease-in-out;
    transform-style: preserve-3d;
    width: 100%;
    z-index: 5000;

    &.aperto {
        top: 0 !important;
    }

    ul {
        background-attachment: scroll;
        background-position: center;
        background-size: 290px 400px;
        background-repeat: no-repeat;
        margin: 0 auto;
        padding: 0;
        position: relative;
        text-align: center;
        top: 50%;
        transition: all .3s ease-in-out;
        transform: translateY(-50%);

        @media (min-width: 768px) {
            background-size: 349px 480px;
        }

        @include sfondo-menu();

        a {

            @media (min-width: 992px) {
                display: block;
                margin: 0 auto;
                width: 38%;
            }

            &:link,
            &:active,
            &:visited {
                color: $black;
                text-decoration: none;
            }

            &:not(:first-child):hover {
                li {
                    transform: rotate(-25deg) !important;
                }
            }

            &.voce-attiva {
                li {
                    transform: rotate(-25deg) !important;
                }
            }

            li {
                list-style: none;
                font-family: $acagiBold;
                font-size: 3.8rem;
                font-weight: bold;
                padding: .2em;
                transition: all .3s ease-in-out;
                text-transform: uppercase;

                @media (min-width: 1440px) {
                    font-size: 4.8rem;
                    padding: .5em;
                }

                img {
                    height: auto;
                    max-height: 65px;
                    max-width: 220px;
                    width: 100%;
                }
            }
        }
    }
}

// Hamburger
#pulsante-menu {
    left: 0;
    position: absolute;
    right: auto;
    top: 0;
    z-index: 6000;

    div:hover {
        cursor: pointer;
        background-color: $yellow-1;
        transition: all .3s ease-in-out;

        .etichetta {
            color: $black;
        }

        .hamburger-inner,
        .hamburger-inner::after,
        .hamburger-inner::before {
            background-color: $black;
            opacity: 1;
        }
    }

    div.pulsante-attivo {
        background-color: $yellow-1 !important;

        .etichetta {
            color: $black !important;
        }

        .hamburger-inner,
        .hamburger-inner::after,
        .hamburger-inner::before {
            background-color: $black !important;
            opacity: 1 !important;
        }
    }

    &,
    div {
        max-height: 40px;
        padding: 0 16px 16px 0;
    }

    div {
        background-color: $white;
        display: inline-block;
        width: auto;
    }

    .etichetta {
        color: $black;
        font-family: $acagiBold;
        font-size: 2.4rem;
        font-weight: bold;
        position: relative;
        text-transform: uppercase;
        top: -2px;
        transition: all 1s ease-in-out;
    }

    .hamburger.hamburger--collapse {

        &,
        &:focus {
            outline: none !important;
        }
    }

    .hamburger-inner {

        & {
            transition: all .8s ease-in-out;
        }

        &::after {
            transition: all .4s ease-in-out;
        }

        &::before {
            transition: all .6s ease-in-out;
        }
    }
}

// Torna Su
#torna-su {

    aside {
        background-color: $yellow-1;
        bottom: 0;
        color: $black;
        font-size: 2.4rem;
        opacity: 0;
        padding: .7em 16px .5em;
        position: fixed;
        right: 0;
        top: auto;
        transition: all .3s ease-in-out;
        z-index: 6000;

        &.visibile {
            opacity: 1 !important;
        }
    }
}

// New Logo
#new-logo {
    background-color: $white;
    left: auto;
    max-width: 1440px;
    max-height: 40px;
    padding: 0 16px 0 14%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 6000;

    @media (min-device-width: 320px) and (max-device-width: 320px) and (min-device-height: 568px) and (max-device-height: 568px) {
        padding: 0 16px 0 18%;
    }

    @media (min-width: 375px) {
        padding: 0 16px 0 28%;
    }

    @media (min-device-width: 375px) and (max-device-width: 375px) and (min-device-height: 667px) and (max-device-height: 667px) {
        padding: 0 16px 0 30%;
    }

    @media (min-width: 480px) {
        padding: 0 16px;
    }

    div {
        background-attachment: scroll;
        background-image: url('../img/menu-logo.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        display: inline-block;
        height: auto;
        min-width: 114px;
        padding: 0 0 2.5em;
    }
}

.embed-responsive-16by9 {
    padding-bottom: 25em;

    @media (min-width: 768px) {
        padding-bottom: 26.3em;
    }

    @media (min-width: 992px) {
        padding-bottom: 30em;
    }
}

@import "cookies";
@import "privacy";
