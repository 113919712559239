// Inizio SCSS
// Privacy

.privacy-policy__container__articolo__link,
ul li a {
  color: $yellow-1;
  font-weight: bold;
  text-decoration: none;
  transition: all .3s ease-in-out;

  &:hover {
    color: $black;
  }
}

ul {
  margin: 2em 0;
  padding: 0 0 0 16px;

  li {
    font-family:"Akagi Pro W00 Light";
    font-size: 24px;
    list-style: square;
    margin: 5px 0;
  }
}
// Fine SCSS