// Inizio SCSS
.container-cookies {
  background-color: $black;
  bottom: 0;
  color: $white;
  font-family: $acagiBold, 'sans-serif';
  font-size: 14px;
  margin: 0 auto;
  padding: 1em 16px;
  position: fixed;
  width: 100%;
  text-align: center;
  z-index: 9999;

  &.aperto {
    top: 0;
  }

  &.chiuso {
    background-color: transparent !important;
    max-width: 25%;
    text-align: left !important;
  }

  &.chiuso,
  &#{__policy},
  &#{__policy--avviso}.chiuso {
    padding: 0 !important;
  }

  &,
  &#{__policy__corpo__link},
  &#{__policy__cta},
  &#{__chiudi-cookies},
  &#{__chiudi-impostazioni} {
    transition: all .3s ease-in-out;
  }

  &#{__policy__corpo__link},
  &#{__policy__cta},
  &#{__chiudi-cookies},
  &#{__chiudi-impostazioni} {
    color: $yellow-1;
  }

  &#{__policy__corpo__link},
  &#{__policy__cta} {
    text-decoration: none !important;
  }

  &#{__chiudi-cookies},
  &#{__chiudi-impostazioni} {
    position: absolute;
    right: 16px;
    top: 1em;

    &:hover {
      color: $white;
      cursor: pointer;
    }
  }

  &.aperto,
  &#{__policy--impostazioni} {
    height: 100%;
  }

  &#{__policy--impostazioni} {
    overflow: auto;
  }

  &#{__policy} {

    &.chiuso {
      margin: 0;
    }
  }

  &#{__policy__cta} {
    background-color: $white;
    color: $black;
    display: inline-block;
    margin: 1em 16px;
    padding: .5em 16px;

    &:hover {
      background-color: $yellow-1;
    }

    &.singolo {
      margin: 0 !important;
    }

    @media (min-width: 480px) {
      margin: 0 16px;
    }
  }

  &#{__policy__corpo__link} {

    &:hover {
      color: $white;
    }
  }

  &#{__policy__form} {
    outline: none;
    padding: 0;
    text-align: left;

    @media (min-width: 480px) {
      padding: 2em 24px;
    }

    @media (min-width: $break_3) {
      padding: 2em 0;
    }

    @media (min-width: 1920px) {
      padding: 2em 72px;
    }

    &#{__legenda} {
      display: none;
    }

    input {
      margin: 0 16px 0 0;
    }

    label {
      max-width: 62%;

      @media (min-width: 480px) {
        max-width: 100%;
      }
    }
  }

  p {
    padding: 0 16px 1em;
  }
}

// Fine SCSS